<template>
  <common-table
      :headers="headers"
      :listData="listData"
      @changePage="changePage"
      :loading="loading"
      :btnAction="true"
      @search="search"
      @remove="remove"
      @save="save"
      @edit="edit"
      ref="ct"
      pageTitle="类目"
      :rightConfig="rightConfig"
      :leftConfig="leftConfig"
      :listDeploy="listDeploy"
  >
  </common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/hand-greeting/category-template.js';
import categoryTemplateProps from "./category-template-props.vue";

export default {
  mixins: [commonCurd],
  data() {
    return {
      api,
      // 排序字段，不填默认sqlid
      sortname: 'seq',
      // 定义右侧按钮
      rightConfig: [
        {label: '新建', icon: 'zengjia', event: 'add'}
      ],
      // 定义左侧搜索框
      leftConfig: [{type: 'input', label: '输入类目代码/名称', property: 'searchkey'}],
      // 表格头部字段
      headers: [
        {text: '类目代码', value: 'code'},
        {text: '类目名称', value: 'description'},
        {text: '创建时间', value: 'createtime'},
        {text: '操作', value: 'actions', width: 80}
      ],
      // 表单字段
      listDeploy: {
        row: [
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'code',
            hasEditDisabledProperty: true,
            connector: {
              props: {
                required: true,
                label: "类目代码"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'description',
            connector: {
              props: {
                required: true,
                label: "类目名称"
              }
            }
          },
          {
            cols: 12,
            subassembly: "div",
            connector: {
              class: 'font-weight-bold',
              domProps: {
                innerHTML: '商品规格'
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'specs1',
            connector: {
              props: {
                required: true,
                label: "商品规格1"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'specs2',
            connector: {
              props: {
                label: "商品规格2"
              }
            }
          },
          {
            cols: 12,
            subassembly: categoryTemplateProps,
            property: 'tags'
          }
        ]
      }
    }
  }
}
</script>
