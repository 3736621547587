<template>
  <div>
    <strong class="asterisk-div" style="font-size: 14px; color: #666666" v-if="showTitle">
      {{ title }}
    </strong>
    <div class="d-flex flex-wrap">
      <v-col :cols="col" v-for="(item, index) in items" :key="index" class="d-flex mt-5">
        <v-text-field
            autocomplete="off"
            hide-details
            dense
            :label="title + (index + 1)"
            :value="item"
            @input="$set(items, index, $event)"
        />
        <div v-if="index !== 0 || items.length > 1" class="ml-1">
          <v-icon
              @click="remove(index)"
              size="28"
              style="cursor: pointer" class="iconfont"
              :style="{color: 'var(--v-redness-base)'}">
            iconfont icon-shanchu-fill
          </v-icon>
        </div>
      </v-col>
      <div class="pa-0 mt-8">
        <v-btn color="primary" depressed style="width: 28px; min-width: 28px; height: 28px !important;"
               @click="add">
          <v-icon style="cursor: pointer" class="iconfont" size="16">
            iconfont icon-zengjia
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ['']
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '商品属性'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    col: {
      type: Number,
      default: 6
    }
  },
  methods: {
    add() {
      this.items.push('')
    },
    remove(index) {
      this.items.splice(index, 1)
    }
  },
  watch: {
    items: {
      handler(v) {
        this.$emit('change', v.join(','))
      },
      immediate: true,
      deep: true
    },
    value: {
      handler(v) {
        if (v) {
          this.items = v.split(',')
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
